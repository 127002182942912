import { CSSProperties, FC} from 'react';

const ShareButtonStyle: CSSProperties =
{
    position: "absolute",
    width: 32,
    margin: 3,
    right: 0
}

const ShareBackgroundButtonStyle: CSSProperties =
{
    background: "black",
    position: "absolute",
    right: 0,
    width: 38,
    height: 38,
    borderRadius: 25,
    opacity: 0.2
}

const ShareButtonContainerStyle: CSSProperties = {
  position: "absolute",
  margin: "1rem",
  right: 0
}

const ShareButton: FC = () => {
  const handleShare = () => {
    if (navigator.share) {
        navigator.share({
            title: 'The World of Tim Burton',
            url: window.location.href,
        });
    } else {
        console.log('Web Share API is not supported on this browser.');
    }
  };

  return (
    <div style={ShareButtonContainerStyle} onClick={handleShare}>
        <img style={ShareButtonStyle} onClick={handleShare} src={require("../../icons/share.png")}/>
        <div style={ShareBackgroundButtonStyle}/>
    </div>
  );
};

export default ShareButton;