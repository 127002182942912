function getiOSMajorVersion() {
    const userAgent = window.navigator.userAgent;
    const iOSMatch = userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
    if (iOSMatch) {
        const majorVersion = parseInt(iOSMatch[1], 10);
        return majorVersion;
    }
    return null; // Not an iOS device
}
function isSafariMobile() {
    var userAgent = navigator.userAgent || navigator.vendor;
    // Check for Safari pattern
    var isSafari = /Version\/[\d.]+.*Safari/.test(userAgent);
    // Check for Chrome on iOS pattern
    //var isChromeOnIOS = /CriOS\/[\d.]+/.test(userAgent);
    // Determine if it's not Safari
    return isSafari;
}
export function openSnapchat(lensPublicUrl, lensDeepLink) {
    if (/android/i.test(navigator.userAgent) && lensDeepLink) {
        // Android
        window.location.href = lensDeepLink;
    }
    else if (/iPad|iPhone|iPod/.test(navigator.userAgent) && lensDeepLink && lensPublicUrl) {
        // iOS
        if (isSafariMobile()) {
            const iOSVersion = getiOSMajorVersion();
            if (iOSVersion && iOSVersion >= 17) {
                window.location.href = lensPublicUrl;
            }
            else if (lensDeepLink) {
                window.location.href = lensDeepLink;
            }
        }
        else {
            window.location.href = lensDeepLink;
        }
    }
    else {
        console.log("Non-mobile platform detected.");
    }
}
