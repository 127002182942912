import { FC, CSSProperties } from 'react';

const DownloadButtonStyle: CSSProperties =
{
    margin: "0 3vh 3vh 3vh",
    borderRadius: 100,
    border: "none",
    fontFamily: "Avenir Next,Helvetica,sans-serif",
    padding: "8px 16px",
    fontWeight: 500,
    background: "white",
    color: "black",
    fontSize: 14,
    lineHeight: "16px"
}

const DownloadButton: FC = () => {
    const detectPlatformAndRedirect = () => {
        if (/android/i.test(navigator.userAgent)) {
            // Android
            window.location.href = 'https://play.google.com/store/apps/details?id=com.snapchat.android';
        } else if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
            // iOS
            window.location.href = 'https://apps.apple.com/fr/app/snapchat/id447188370';
        } else {
            console.log("Non-mobile platform detected.")
        }
    };

    return (
        <button onClick={detectPlatformAndRedirect} style={DownloadButtonStyle}>Download Snapchat</button>
    );
};

export default DownloadButton;
