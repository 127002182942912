import React, { CSSProperties, FC, useState } from "react";
import ShareButton from "./components/ShareButton/ShareButton";
import DownloadButton from "./components/DownloadButton/DownloadButton";
import { openSnapchat } from "./utils/redirect";
import { CameraKitContext } from "./contexts/CameraKitContext";
import { CameraSourceContext } from "./contexts/CameraSourceContext";
import Player from "./components/Player/Player";

// Set apiToken with an API token for the desired app. (https://kit.snapchat.com/manage)
const apiToken = process.env.REACT_APP_CK_API_TOKEN
const defaultLensGroup = process.env.REACT_APP_CK_LENS_GROUP_ID; // Camera kit lens group
const lensPublicUrl = process.env.LENS_PUBLIC_URL;
const lensDeepLink = process.env.LENS_DEEPLINK;

const TapButtonStyle: CSSProperties =
{
    margin: "0 3vh 3vh 3vh",
    fontFamily: "Avenir Next,Helvetica,sans-serif",
    fontWeight: 500,
    color: "black",
    fontSize: 16,
    lineHeight: "16px",
    background: "none",
    padding: "10px 20px",
    border: "1px solid black",
    borderRadius: 100
}

const CentralDivStyle: CSSProperties =
{
    display: "flex",
    position: "absolute",
    top: "50%"
}

const App: FC = () => {

  const isMobile  = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const isAndroid = /android/i.test(navigator.userAgent);
  const [isMotionPermissionGranted, setIsMotionPermissionGranted] = useState(isAndroid);

  if (isAndroid) {
    navigator.mediaDevices.getUserMedia({ video: true });
    setTimeout(() => openSnapchat(lensPublicUrl, lensDeepLink), 1500);
  } else {
    openSnapchat(lensPublicUrl, lensDeepLink);
  }

  // Request permission for DeviceMotion and DeviceOrientation
  function requestMotionPermission() {
    const isMotionAndOrientationBrowser =
          typeof DeviceMotionEvent !== "undefined" &&
          typeof DeviceOrientationEvent !== "undefined" &&
          typeof (DeviceMotionEvent as any).requestPermission === "function" &&
          typeof (DeviceOrientationEvent as any).requestPermission === "function";
    if (isMotionAndOrientationBrowser) {
      Promise.all([
        (DeviceMotionEvent as any).requestPermission(),
        (DeviceMotionEvent as any).requestPermission()
      ])
      .then(([motionPermission, orientationPermission]) => {
        if (motionPermission === 'granted' && orientationPermission === 'granted') {
          setIsMotionPermissionGranted(true);
        }
      })
      .catch((error) => console.log(error));
    } else {
      // Permission is not required
      setIsMotionPermissionGranted(true);
    }
  }

  return (
    <div>
      { isMobile &&
          <div className="container">
            <ShareButton />
            <div className="canvas-container">
              <div className="footer">
                <img className="logo" src={require("./icons/logo.png")} />
                <DownloadButton/>
              </div>
              { isMotionPermissionGranted ?
                <CameraKitContext
                  apiToken={apiToken!}
                  lensGroupId={defaultLensGroup!}
                  shouldUseWorker={true}
                  >
                  <CameraSourceContext
                    initialDeviceId={undefined}
                    fpsLimit={undefined}
                  >
                    <Player />
                  </CameraSourceContext>
                </CameraKitContext> :
                <div style={CentralDivStyle}>
                  <button onClick={requestMotionPermission} style={TapButtonStyle}>Tap to start</button>
                </div>
              }
            </div>
          </div>
      }
      { !isMobile &&
        <div className="non-mobile-message">
          Please open the Web AR player on a mobile device.
        </div>
      }
    </div>
  );
}



export default App;

